import { GET_ACCESS_LIST, ADD_ACCESS, UPDATE_ACCESS, DELETE_ACCESS, GET_ACCESS_DETAIL } from 'store/actions/systemAccess';

const initialState = {
  isAuthenticated: false,
  loader:false,
  accessList:[],
  accessDetail:{},
  totalItemsCount:0,
  itemsCountPerPage:0,
};

const getAccessList = (state, payload) => {
    const { callback } = payload;
    if (typeof callback === 'function') {
      callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      accessList:payload?.data,
      itemsCountPerPage:payload?.data.per_page,
      totalItemsCount:payload?.data.total_count
    };
    return stateObj;
};

const getAccessDetail = (state, payload) => {
  const { callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    accessDetail:payload?.data,
  };
  return stateObj;
};


const addAccess = (state, payload) => {
  const { callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    accessList:payload?.data,
  };
  return stateObj;
};

const updateAccess = (state, payload) => {
  const { callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    accessList:payload?.data,
  };
  return stateObj;
};

const deleteAccess = (state, payload) => {
  const { callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    accessList:{},
  };
  return stateObj;
};

const SystemAccess = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case GET_ACCESS_LIST:
          return getAccessList(state, payload); 
        case ADD_ACCESS:
          return addAccess(state, payload); 
        case GET_ACCESS_DETAIL:
          return getAccessDetail(state, payload); 
        case UPDATE_ACCESS:
          return updateAccess(state, payload); 
        case DELETE_ACCESS:
          return deleteAccess(state, payload); 
        default:
        return state;
    };
}
export default SystemAccess;
