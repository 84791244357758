import React, { useState, useEffect } from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import {  Link } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { CMS } from 'helpers/viewContent';
import {  FORMAT_TEXT } from "helpers/common";
//imported
import * as Path from 'routes/paths';
import * as actions from 'store/actions/index.js';
import {getCms_} from 'store/services/pagesService';

const Listing = ({dispatch}) => {
  const [listing, setListing] = useState([]);
  async function getData() {
    try {
      dispatch(actions.persist_store({ loader: true }));
      let res = await dispatch(getCms_());
      setListing(res)
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }
// console.log(listing)
  useEffect(() => {
    getData();
  }, []);
    return(
        <>
            <Helmet title={CMS.CURRENT_MODULE} />
            <div className="page-header">
            <h3 className="page-title">
                <span className="page-title-icon bg-gradient-primary text-white me-2">
                <i className="mdi mdi-table-large menu-icon"></i>
                </span>  {CMS.CURRENT_MODULE}
            </h3>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to={Path.cms}>{CMS.CURRENT_MODULE}</Link></li>
                  <li className="breadcrumb-item active" aria-current="page">Listing</li>
                </ol>
              </nav>
            </div>
            <div className="row">
             <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">
                        <div className="row">
                          <div className="col-md-6">
                            Listing
                          </div>
                          <div className="col-md-4">
                          </div>
                          <div className="col-md-2">
                            <Link to={Path.cms_form}><button type="button" class="btn btn-sm btn-gradient-primary"> + Add</button></Link>
                          </div>
                        </div>
                      </h4>
                    <div class="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th> Title </th>
                            <th> Slug </th>
                            <th> Url </th>
                            <th> Action </th>
                          </tr>
                        </thead>
                        <tbody>
                          {listing.length> 0 && listing.map((list, i) => {
                            return(
                              <tr key={i}>
                                <td className="py-1">
                                {list?.description ? FORMAT_TEXT(list?.description) : '---'}
                                </td>
                                <td>{list?.key} </td>
                                <td><a href={`${Path.cms_Page}/${list?.key}`} target="_blank">View here</a></td>
                                <td>
                                  <Link to={`${Path.cms_form}/${list?.key}`} ><label class="badge badge-info" style={{cursor:'pointer'}} title="Edit">Edit</label></Link>&nbsp;&nbsp;
                                
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </>

    )
}

const mapStateToPros = (state) => {
  return{
    loader: state.Pages.loader,
  }
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
mapStateToPros,
mapDispatchToProps
)(Listing);