//action types
export const GET_APPLICATION = 'GET_APPLICATION';
export const GET_APPLICATION_DETAIL = 'GET_APPLICATION_DETAIL';
export const ANSWER_APPLICATION = 'ANSWER_APPLICATION';
export const UPDATE_STATUS_APPPLICATION = 'UPDATE_STATUS_APPPLICATION';
export const DOCUMENT_APPLICATION = 'DOCUMENT_APPLICATION';
export const REMOVE_APPLICATION = 'REMOVE_APPLICATION';
export const ROLE_ASSIGN_USER = 'ROLE_ASSIGN_USER';
export const UPDATE_ASSIGN_USER = 'UPDATE_ASSIGN_USER';




//get 
export function getApplication(data, callback) {
  return {
    type: GET_APPLICATION,
    payload : {data, callback},
  };
}
export function getApplicationDetail(data, callback) {
  return {
    type: GET_APPLICATION_DETAIL,
    payload : {data, callback},
  };
}
export function getAnswersForApplication(data, callback) {
  return {
    type: ANSWER_APPLICATION,
    payload : {data, callback},
  };
}

export function getRequiredDocumentsApplication(data, callback) {
  return {
    type: DOCUMENT_APPLICATION,
    payload : {data, callback},
  };
}
export function updateApplicationStatusForAdmin(data, callback) {
  return {
    type: UPDATE_STATUS_APPPLICATION,
    payload : {data, callback},
  };
}

export function removeApplication(data, callback) {
  return {
    type: REMOVE_APPLICATION,
    payload : {data, callback},
  };
}

export function roleAssignUser(data, callback) {
  return {
    type: ROLE_ASSIGN_USER,
    payload : {data, callback},
  };
}

export function UpdateAssignUser(data, callback) {
  return {
    type: UPDATE_ASSIGN_USER,
    payload : {data, callback},
  };
}