const moment = require('moment');

export function FORMAT_TEXT(text) {
    const text_ = text?.length > 20 ? text?.substring(0, 20) + '...' : text
    return text_
  }

  export function DATE_FORMAT(val) {
    let timeVal = parseInt(val)
      const formattedDate = moment(timeVal).format('DD-MMM-YYYY h:mmA');
    return formattedDate
  }