import { GET_LIST } from 'store/actions/transaction';

const initialState = {
  isAuthenticated: false,
  loader:false,
  tranList:[],
  totalItemsCount:0,
  itemsCountPerPage:0,
};

const getList = (state, payload) => {
    const { callback } = payload;
    if (typeof callback === 'function') {
      callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      tranList:payload?.data,
      itemsCountPerPage:payload?.data.per_page,
      totalItemsCount:payload?.data.total_count
    };
    return stateObj;
  };

const Transaction = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case GET_LIST:
          return getList(state, payload); 
        default:
        return state;
    };
}
export default Transaction;
