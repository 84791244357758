import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import persistStore from './persistStore';
//imported
import Auth from '../reducers/Auth';
import Application from '../reducers/Application';
import File from '../reducers/File';
import Dashboard from '../reducers/Dashboard';
import SystemAccess from '../reducers/SystemAccess';
import Transaction from '../reducers/Transaction';
import Pages from '../reducers/Pages';

const RootReducer = combineReducers({
		Auth,
		Application,
		File,
		Dashboard,
		SystemAccess,
		Transaction,
		Pages,
		persistStore,
		form: formReducer,
	});
export default RootReducer;
