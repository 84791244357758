export const PAGE_LIMIT = 10;
export const LOGIN = {
    CURRENT_MODULE : 'Login',
    TOP_CONTENT:'WELCOME BACK !',
    MAIN_CONTENT : 'Sign in to continue zenaura',
    BUTTON:'SIGN IN'
}
export const DASHBOARD = {
    CURRENT_MODULE : 'Dashboard',
}
export const APPLICATION = {
    CURRENT_MODULE : 'Applications',
    CURRENT_LIST : 'Listing',
    CURRENT_VIEW : 'Detail',
}
export const DOCUMENTS = {
    CURRENT_MODULE : 'Application',
    CURRENT_LIST : 'Listing',
    CURRENT_VIEW : 'Detail',
}
export const TRANSACTION = {
    CURRENT_MODULE : 'Transaction',
    CURRENT_LIST : 'Listing',
    CURRENT_VIEW : 'Detail',
}
export const SYSTMENACCESS = {
    CURRENT_MODULE : 'System Access',
    CURRENT_LIST : 'Listing',
    CURRENT_VIEW : 'Detail',
}
export const CMS = {
    CURRENT_MODULE : 'Content Pages',
    CURRENT_LIST : 'Listing',
    CURRENT_VIEW : 'Detail',
}