
export const APP_PATH = 'api'
//auth
export const LOGIN = "/auth/signin";
export const LOGOUT = "/auth/signout";
//application
export const APPLICATION_LIST = '/application/searchApplicationsPaging'
export const APPLICATION_QUESTION = '/questions/getAnswersForApplicationId'
export const APPLICATION_DOCUMENT = '/application/getRequiredDocumentsForApplication'
export const APPLICATION_STATUS = '/application/updateApplicationStatusForAdmin'
export const DELETE_APPLICATION = '/application/removeApplication'
export const ASSIGN_ROLE_LIST = '/admin/getAllUsers'
export const UPDATED_APLLICATION_ROLE = '/admin/assignApplicationToAdminId'
export const APPLICATION_DETAIL = '/admin/getApplicationDetails'
export const ADMIN_MARK_READ_NOTIFICATION = '/admin/markReadAdminNotifications'
//file
export const UPLOAD_IMAGE_PATH = "/admin/uploadAdminFile";
export const DELETE_IMAGE_PATH = "/application/deleteFile";
//dashboard
export const GET_DASHBOARD_ = "/admin/getDashboard";


export const GET_ADMIN_NOTIFICATIONS = '/admin/getAdminNotifications';
export const GENERATE_PDF = 'api/auth/generatePdf';

//system access
export const GET_ACCESS_LIST = "/admin/getAllUsers";
export const ADD_ACCESS = "/admin/addUser";
export const UPDATE_ACCESS = "/admin/updateUser";
export const DELETE_ACCESS = "/admin/deleteUser";
export const GET_ACCESS_DETAIL = "/admin/getUserDetails";
//transaction
export const GET_TRANSCATION_LIST = "/payment/getAllPaymentsRecord";
//CMS
export const GET_CMS_LIST = "/admin/getContentListing";
export const ADD_CMS = "/admin/addContent";
export const UPDATE_CMS = "/admin/updateUser";
export const DELETE_CMS = "/admin/deleteUser";
export const VIEW_CMS_DETAIL = "/cron/getContentViaKey";
