import { GET_CMS, ADD_CMS, UPDATE_CMS, DELETE_CMS, VIEW_CMS } from 'store/actions/pages';

const initialState = {
  isAuthenticated: false,
  loader:false,
  cmsList:[],
  cmsDetail:{}
};

const getCms = (state, payload) => {
    const { callback } = payload;
    if (typeof callback === 'function') {
      callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      cmsList:payload,
    };
    return stateObj;
};

const addCms = (state, payload) => {
  const { callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    cmsList:payload,
  };
  return stateObj;
};

const updateCms = (state, payload) => {
  const { callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    cmsList:payload,
  };
  return stateObj;
};

const viewCms = (state, payload) => {
  const { callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    cmsDetail:payload,
  };
  return stateObj;
};


const deleteCms = (state, payload) => {
  const { callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    cmsList:{},
  };
  return stateObj;
};

const Pages = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case GET_CMS:
          return getCms(state, payload); 
        case ADD_CMS:
          return addCms(state, payload); 
        case UPDATE_CMS:
          return updateCms(state, payload); 
        case DELETE_CMS:
          return deleteCms(state, payload); 
        case VIEW_CMS:
          return viewCms(state, payload); 
        default:
        return state;
    };
}
export default Pages;
