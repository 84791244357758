import React, { useState, useEffect } from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import {  Link } from 'react-router-dom';
import { SYSTMENACCESS, PAGE_LIMIT } from 'helpers/viewContent';
import {  FORMAT_TEXT } from "helpers/common";
import ReactPagination from "components/shared/ReactPagination";
import { DELETE_ALERT } from "helpers/constantsMessage"
//imported
import * as Path from 'routes/paths';
import * as actions from 'store/actions/index.js';
import {getAccessList_, deleteAccess_} from 'store/services/systemAccessService';

const Listing = ({dispatch, itemsCountPerPage, totalItemsCount}) => {
    //pagination
    const [activePage, setActivePage] = useState(1);
    const [listing, setListing] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    async function getData() {
      try {
        let query = {
          page: activePage - 1,
          size: PAGE_LIMIT,
        };
        dispatch(actions.persist_store({ loader: true }));
        let res = await dispatch(getAccessList_(query));
        setListing(res?.data)
        dispatch(actions.persist_store({ loader: false }));
      } catch (err) {
        dispatch(actions.persist_store({ loader: false }));
        console.log(err);
      }
    }

    useEffect(() => {
      getData();
    }, [activePage]);

    const handleSearchChange = async (event) => {
      const inputValue = event.target.value;
      setSearchValue(inputValue);
  
      // If the search bar is empty, fetch the listing data again
      if (inputValue === '') {
        await getData();
      } else {
        // Filter the listing based on the entered search value for both applicantName and email (assign user)
        const filteredListing = listing.filter(
          (item) =>
            item?.username?.toLowerCase().includes(inputValue.toLowerCase()) ||
            item?.email?.toLowerCase().includes(inputValue.toLowerCase())
        );
  
        setListing(filteredListing);
      }
    };

    //pagination
    const handlePageChange = (page) => {
      setActivePage(page);
      window.scrollTo(0, 0);
    };

    //delete
    const deleteUsers_ = async (id) => {
      try {
        await DELETE_ALERT().then((willDelete) => {
          if (willDelete) {
            try {
              dispatch(deleteAccess_(id)).then(() => {
                getData();
              });
            } catch (err) {
              console.log(err);
            }
          }
        });
      } catch (err) {
        console.log(err);
      }
    };

    return(
        <>
            <Helmet title={SYSTMENACCESS.CURRENT_MODULE} />
            <div className="page-header">
            <h3 className="page-title">
                <span className="page-title-icon bg-gradient-primary text-white me-2">
                <i className="mdi mdi-contacts menu-icon"></i>
                </span>  {SYSTMENACCESS.CURRENT_MODULE}
            </h3>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to={Path.system_access}>{SYSTMENACCESS.CURRENT_MODULE}</Link></li>
                  <li className="breadcrumb-item active" aria-current="page">Listing</li>
                </ol>
              </nav>
            </div>
            <div className="row">
             <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">
                      <div className="row">
                        <div className="col-md-6">
                          Listing
                        </div>
                        <div className="col-md-4">
                            <input className="form-control"  autoComplete="new-password" name="search" type="text"  placeholder="Search" 
                            pattern="[a-zA-Z0-9\s]+"
                            value={searchValue}
                            onChange={handleSearchChange}
                          />
                        </div>
                        <div className="col-md-2">
                          <Link to={Path.system_access_add}><button type="button" class="btn btn-sm btn-gradient-primary"> + Add</button></Link>
                        </div>
                      </div>
                    </h4>
                    <div class="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th> Sr No. </th>
                            <th> Username </th>
                            <th> Email </th>
                            <th> Role </th>
                            <th> Status </th>
                            <th> Action </th>
                          </tr>
                        </thead>
                        <tbody>
                          {listing.length > 0 && listing.map((list, i) => {
                            return(
                            <tr key={i}>
                              <td>{i+1}.</td>
                              <td className="py-1">
                              {list?.username ? FORMAT_TEXT(list?.username) : '---'}
                              </td>
                              <td>{list?.email ? FORMAT_TEXT(list?.email) : '---'} </td>
                              <td>{list?.roles.length>0 ? list?.roles[0].name : '---'} </td>
                              <td><label class={`badge badge-${list?.verified == 1 ? 'success' : 'danger'}`}>{list?.verified == 1 ? 'Active' : 'Inactive'}</label></td>
                              <td>
                                <Link to={`${Path.system_access_add}/${list?.id}`} ><label class="badge badge-info" style={{cursor:'pointer'}} title="Edit">Edit</label></Link>&nbsp;&nbsp;
                                <label class="badge badge-danger" style={{cursor:'pointer'}} title="Delete" onClick={() => deleteUsers_(list?.id)}>Delete</label>
                              </td>
                            </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                    {listing.length > 0 && (
                      <ReactPagination
                        activePage={activePage}
                        itemsCountPerPage={itemsCountPerPage}
                        totalItemsCount={totalItemsCount}
                        handlePageChange={handlePageChange}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
        </>

    )
}

const mapStateToPros = (state) => {
  return{
    itemsCountPerPage: state.SystemAccess.itemsCountPerPage,
    totalItemsCount: state.SystemAccess.totalItemsCount,
    loader: state.SystemAccess.loader,
  }
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
mapStateToPros,
mapDispatchToProps
)(Listing);