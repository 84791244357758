import React from "react";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CloseIcon from '@mui/icons-material/Close';
import {Divider, IconButton, Badge, Popover, List, ListItem, ListItemText, Typography} from '@mui/material';
import {DATE_FORMAT} from "../helpers/common"; // Import Close Icon from Material UI

export default NotificationPopup;


function NotificationPopup({
                               notifications = [],
                               unread = 0,
                               totalNotifications = 0,
                               handleSeeMore,
                               handleItemClick,
                               handleBellClick,
                               handlePopoverClose,
                               openPopover,
                               anchorEl,
                           }) {
    return (

        <div>
            {/* Notification Icon with Badge */}
            <IconButton color="inherit" onClick={handleBellClick}>
                <Badge badgeContent={unread} color="error">
                    <NotificationsIcon/>
                </Badge>
            </IconButton>

            {/* Popover for Notifications */}
            <Popover
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{vertical: "center", horizontal: "center"}} // Centered Popup
                transformOrigin={{vertical: "center", horizontal: "center"}} // Centered Popup
                anchorReference="anchorPosition"  // This makes the popover open relative to the viewport instead of an element
                anchorPosition={{top: '50%', left: '50%'}} // Centered position
                PaperProps={{
                    sx: {
                        width: '400px',
                        padding: 2,
                        backgroundColor: '#f6f6f6',
                        borderRadius: '8px',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                        transform: 'translate(-50%, -50%)', // Center the popover
                    },
                }}
            >
                <List style={{padding: 0}}>
                    {/* Header - Notification Title */}
                    <ListItem
                        style={{backgroundColor: "#e0e0e0", borderTopLeftRadius: "8px", borderTopRightRadius: "8px"}}>
                        <ListItemText
                            primary={<Typography
                                style={{fontSize: '1rem', fontWeight: 'bold'}}>Notifications</Typography>}
                            secondary={<Typography style={{fontSize: '0.8rem', color: 'gray'}}>Latest
                                notifications</Typography>}
                        />
                    </ListItem>

                    <Divider/>

                    {/* Render Notifications */}
                    {notifications.length > 0 ? (
                        notifications.map((notification, index) => (
                            <ListItem
                                key={index}
                                divider
                                button
                                onClick={() => handleItemClick(notification)} // Handle click for each item
                                style={{
                                    backgroundColor: notification.readByAdmin === 0 ? "#51acff" : "white", // Highlight unread
                                    borderRadius: "8px",
                                    marginBottom: "8px",
                                    transition: "background-color 0.3s ease"
                                }}
                                onMouseOver={(e) => e.currentTarget.style.backgroundColor = "#e0e0e0"}
                                onMouseOut={(e) => e.currentTarget.style.backgroundColor = notification.readByAdmin === 0 ? "#51acff" : "white"}
                            >
                                <ListItemText
                                    primary={<Typography style={{
                                        fontSize: '0.9rem',
                                        fontWeight: '500'
                                    }}>{notification.msg || "No title"}</Typography>}
                                    secondary={<Typography style={{
                                        fontSize: '0.7rem',
                                    }}>{DATE_FORMAT(notification.timestamp)}</Typography>}
                                />
                            </ListItem>
                        ))
                    ) : (
                        <ListItem>
                            <ListItemText
                                primary="No new notifications"
                                style={{fontSize: '0.8rem', color: 'gray'}}
                            />
                        </ListItem>
                    )}

                    <Divider/>

                    {/* "See More" Button */}
                    {notifications.length < totalNotifications && (
                        <ListItem
                            button
                            onClick={handleSeeMore}
                            style={{marginTop: "8px", backgroundColor: "#f0f0f0", borderRadius: "4px"}}
                        >
                            <ListItemText
                                primary="See More"
                                style={{fontSize: '0.9rem', fontWeight: 'bold', textAlign: 'center'}}
                            />
                        </ListItem>
                    )}
                </List>
            </Popover>
        </div>
    );
}

