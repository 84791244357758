import { GET_APPLICATION, ANSWER_APPLICATION, DOCUMENT_APPLICATION, UPDATE_STATUS_APPPLICATION, REMOVE_APPLICATION, ROLE_ASSIGN_USER, UPDATE_ASSIGN_USER, GET_APPLICATION_DETAIL} from 'store/actions/application';

const initialState = {
    isAuthenticated: false,
    applications: [],
    applicationAnswer: [],
    assignUserList:[],
    applicationDetail : {},
    loader:false,
    totalItemsCount:0,
    itemsCountPerPage:0,
};

//get 
const getApplication = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
        ...state,
        isAuthenticated: true,
        applications:data?.data,
        itemsCountPerPage:data.per_page,
        totalItemsCount:data.total_count
    };
  return stateObj;
};
const getApplicationDetail = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
        ...state,
        isAuthenticated: true,
        applicationDetail:data?.data,
    };
  return stateObj;
};

//get 
const getAnswersForApplication = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
        ...state,
        isAuthenticated: true,
        applicationAnswer:data,
    };
  return stateObj;
};

//get 
const getRequiredDocumentsApplication = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
      callback(payload);
  }
  const stateObj = {
      ...state,
      isAuthenticated: true,
      documentsApplication:data,
  };
return stateObj;
};
 
const updateApplicationStatusForAdmin = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
      callback(payload);
  }
  const stateObj = {
      ...state,
      isAuthenticated: true,
  };
return stateObj;
};

const removeApplication = (state, payload) => {
  const { callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    accessList:{},
  };
  return stateObj;
};

const roleAssignUser = (state, payload) => {
  const { callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    assignUserList:[],
  };
  return stateObj;
};


const UpdateAssignUser = (state, payload) => {
  const { callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
  };
  return stateObj;
};


const Application = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case GET_APPLICATION:
          return getApplication(state, payload);
        case GET_APPLICATION_DETAIL:
          return getApplicationDetail(state, payload);
        case ANSWER_APPLICATION:
          return getAnswersForApplication(state, payload);
        case DOCUMENT_APPLICATION:
          return getRequiredDocumentsApplication(state, payload);
        case UPDATE_STATUS_APPPLICATION:
          return updateApplicationStatusForAdmin(state, payload);
        case REMOVE_APPLICATION:
          return removeApplication(state, payload);
        case ROLE_ASSIGN_USER:
          return roleAssignUser(state, payload);
        case UPDATE_ASSIGN_USER:
          return UpdateAssignUser(state, payload);
        default:
        return state;
    };
}
export default Application;