import React, { useState } from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import {  Link } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { TRANSACTION } from 'helpers/viewContent';

//imported
import * as Path from 'routes/paths';
import * as actions from 'store/actions/index.js';

const Detail = (props) => {


    return(
        <>
            <Helmet title={TRANSACTION.CURRENT_MODULE} />
            <div className="page-header">
            <h3 className="page-title">
                <span className="page-title-icon bg-gradient-primary text-white me-2">
                <i className="mdi mdi-chart-bar menu-icon"></i>
                </span>  {TRANSACTION.CURRENT_MODULE}
            </h3>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to={Path.transaction}>{TRANSACTION.CURRENT_MODULE}</Link></li>
                  <li className="breadcrumb-item active" aria-current="page">Detail</li>
                </ol>
              </nav>
            </div>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">UBD689998</h4>
                            <p className="card-description"> User ID : Herman Beck	 </p>
                            <div className="row">
                            <div className="col-md-6">
                                <address>
                                <p className="font-weight-bold">Applicant Name</p>
                                <p> Assigne, </p>
                                <p> Status </p>
                                </address>
                            </div>
                            <div className="col-md-6">
                                <address className="text-primary">
                                <p className="font-weight-bold"> Test App </p>
                                <p className="mb-2"> SubUser </p>
                                <p className="font-weight-bold"><label className="badge badge-danger">Inactive</label> </p>
                                </address>
                            </div>
                            </div>
                        </div>
                        <div className="card-body">
                        <h4 className="card-title">Update Application Status</h4>
                        <div className="col-md-6 grid-margin stretch-card">
                            <div className="card">
                            <div className="card-body">
                                <form>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="">
                                                <input type="radio" className="" checked /> Active 
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="">
                                                <input type="radio" className="" /> Inactive </label>
                                            </div>
                                        </div>
                                    </div>
                               </form>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}


export default Detail