import {postData, getData} from 'helpers/Helper'
import { APP_PATH, LOGIN, LOGOUT } from './apiPath'
import { authLogin, authLogout } from 'store/actions/auth';

//login
export function login(credentials, msz) {
  return (dispatch) => postData(`${APP_PATH}${LOGIN}`, credentials, msz)
    .then((data) => {
      dispatch(authLogin(data));
      return data;
    });
}

//logout
export function logout(credentials) {
  return (dispatch) => postData(`${APP_PATH}${LOGOUT}`, credentials)
    .then((data) => {
      dispatch(authLogout(data));
      return data;
    });
}