import React, {useState, useEffect} from "react";
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link, useHistory} from 'react-router-dom';
import * as Path from 'routes/paths';
const Sidebar = ({isOpen, userProfile}) => { 
     //history
     const history = useHistory();

     const pathname = history.location.pathname.split('/');
  
  
     const filtered = pathname.filter(function (el) {
          if(el !== ""){
              return el;
          }
     });
      
     let path = '/';
  
     if(filtered.length>=3){
          path += filtered[0]+"/"+filtered[1]+"/"+filtered[2];
     }
     else {
         path += filtered[0]+"/"+filtered[1] ?? '';
     }
 
    return(
      <>
      <nav className={`sidebar sidebar-offcanvas ${isOpen ? 'active' : ''}`} id="sidebar">
        <ul className="nav">
          <li className={classNames("nav-item", {'active' : (path===Path.dashboard)})}>
            <Link className="nav-link"  to={Path.dashboard}>
              <span className="menu-title">Dashboard</span>
              <i className="mdi mdi-home menu-icon"></i>
            </Link>
          </li>
          {/* <li className="nav-item">
            <a className="nav-link" data-bs-toggle="collapse" href="#ui-basic" aria-expanded="false" aria-controls="ui-basic">
              <span className="menu-title">Application</span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-format-list-bulleted menu-icon"></i>
            </a>
            <div className="collapse" id="ui-basic">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className="nav-link" to={Path.application}>Listing</Link></li>
              </ul>
            </div>
          </li> */}
          <li  className={classNames("nav-item", {'active' : (path===Path.application || path===Path.application_view || path===Path.applicationview)})}>
            <Link className="nav-link"to={Path.application}>
              <span className="menu-title">Applications</span>
              <i className="mdi mdi-chart-bar menu-icon"></i>
            </Link>
          </li>
          {userProfile?.role === "ROLE_ADMIN" &&
          <>
            <li className={classNames("nav-item", {'active' : (path===Path.system_access || path===Path.system_access_add || path === Path.system_accessadd)})}>
              <Link className="nav-link"to={Path.system_access}>
                <span className="menu-title">System Access</span>
                <i class="mdi mdi-contacts menu-icon"></i>
              </Link>
            </li>
          </>
          }
          <li className={classNames("nav-item", {'active' : (path===Path.transaction)})}>
            <Link className="nav-link"to={Path.transaction}>
              <span className="menu-title">Transaction Listing</span>
              <i className="mdi mdi-chart-bar menu-icon"></i>
            </Link>
          </li>
          {userProfile?.role === "ROLE_ADMIN" &&
          <>
          <li className={classNames("nav-item", {'active' : (path===Path.cms || path===Path.cms_form)})}>
            <Link className="nav-link" to={Path.cms}>
              <span className="menu-title">Content Pages</span>
              <i className="mdi mdi-table-large menu-icon"></i>
            </Link>
          </li>
          </>
          } 
        </ul>
      </nav>
      </>
    )
}
const mapStateToPros = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        loader: state.persistStore.loader,
        user: state.Auth,
        userProfile : state.Dashboard.dashboardProfile
    }
};

export default connect(
    mapStateToPros,
)(Sidebar);
