import React,{useState, useEffect} from "react";
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
//imported
import * as Path from 'routes/paths';
import * as actions from 'store/actions/index.js';
import { useCustomHistory } from "helpers/getHistory"
import {MAIN_LOGO} from "utils/constant"
import {logout} from 'store/services/authService'
import { toast } from "react-toastify";
import {getDashboard_} from 'store/services/dashboardService';

const Header = ({user, dispatch, userProfile, sidebarHideShow}) => {
    //history
    const path = useCustomHistory();
    //logout function
    const handleLogout = async () => {
        try{
            const authToken = user && user.user ? user.user.jwt  :'';
            dispatch(actions.persist_store({ loader: true }));
            await dispatch(logout(authToken)).then((res) => {
              toast.success(res?.data?.message);
              dispatch(actions.persist_store({ loader: false }));
            });
        }catch(err) {
            console.log(err);
            dispatch(actions.persist_store({ loader: false }));         
        }
    };

    async function getProfile_() {
      try{
          dispatch(actions.persist_store({ loader:true}));
          let res = await dispatch(getDashboard_());
          dispatch(actions.persist_store({ loader:false}));
        }catch(err) {
          dispatch(actions.persist_store({ loader:false}));
          console.log(err);
      }
    }
    useEffect(() => {
      getProfile_()
    }, [])

    return(
        <>
        <nav className={`navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row`}>
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
            <a className="navbar-brand brand-logo" href="#"><img src={MAIN_LOGO} alt="logo" style={{height:'50px', width:'120px'}}  /></a>
            <a className="navbar-brand brand-logo-mini" href="#"><img src="/../../assets/images/logo-mini.svg" alt="logo" /></a>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-stretch">
          <button className="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize" onClick={sidebarHideShow}>
            <span className="mdi mdi-menu"></span>
          </button>
          <ul className="navbar-nav navbar-nav-right">
            <li className="nav-item nav-profile dropdown">
              <a className="nav-link dropdown-toggle" id="profileDropdown" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                <div className="nav-profile-img">
                <img src={`${userProfile ? userProfile?.profileImage: '/../../assets/images/faces/face1.jpg'}`} alt="image" />
                  <span className="availability-status online"></span>
                </div>
                <div className="nav-profile-text">
                  <p className="mb-1 text-black">{userProfile?.name}</p>
                </div>
              </a>
              <div className="dropdown-menu navbar-dropdown" aria-labelledby="profileDropdown" onClick={handleLogout}>
                <a className="dropdown-item" href="#">
                  <i className="mdi mdi-logout me-2 text-primary"></i> Signout </a>
              </div>
            </li>
          </ul>
          <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas"  onClick={sidebarHideShow}>
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>
        </>
    )
}

const mapStateToPros = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        loader: state.persistStore.loader,
        user: state.Auth,
        userProfile : state.Dashboard.dashboardProfile
    }
};
function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(Header);
