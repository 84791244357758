import { getData, postData } from 'helpers/Helper'
import {
    APP_PATH,
    APPLICATION_LIST,
    GET_ADMIN_NOTIFICATIONS,
    APPLICATION_QUESTION,
    APPLICATION_DOCUMENT,
    APPLICATION_STATUS,
    DELETE_APPLICATION,
    UPDATED_APLLICATION_ROLE,
    ASSIGN_ROLE_LIST,
    APPLICATION_DETAIL,
    ADMIN_MARK_READ_NOTIFICATION
} from './apiPath'
import { getApplication, getAnswersForApplication, getRequiredDocumentsApplication, updateApplicationStatusForAdmin, removeApplication, UpdateAssignUser, roleAssignUser, getApplicationDetail} from 'store/actions/application';

//get
export function getApplicationList(value, callback) {
  const { page, size, key,status,  dateFrom, dateTo } = value || {};
  let url = `${APP_PATH}${APPLICATION_LIST}`;
  if (page !== undefined && size !== undefined && key !== undefined && status !== undefined) {
    url += `?page=${page}&size=${size}&key=${key}&status=${status}`;
  }

  if (dateFrom) {
    url += `&dateFrom=${dateFrom}`;
  }
  if (dateTo) {
    url += `&dateTo=${dateTo}`;
  }
  return (dispatch) => getData(url, value)
    .then((data) => {
      dispatch(getApplication(data, callback));
      return data;
    });
}

//get
export function getApplicationDetail_(value, callback) {
  let url = `${APP_PATH}${APPLICATION_DETAIL}?applicationId=${value}`;
  return (dispatch) => getData(url, value)
    .then((data) => {
      dispatch(getApplicationDetail(data, callback));
      return data;
    });
}

export function getAnswersForApplicationList(value, callback) {
  //   const { page, limit } = value || {};
  let url = `${APP_PATH}${APPLICATION_QUESTION}?applicationId=${value}&spouseOnly=0`;
  return (dispatch) => getData(url, value)
    .then((data) => {
      dispatch(getAnswersForApplication(data, callback));
      return data;
  });
}

export function getRequiredDocumentsApplication_(value, callback) {
  //   const { page, limit } = value || {};
  let url = `${APP_PATH}${APPLICATION_DOCUMENT}?applicationId=${value}`;
  return (dispatch) => getData(url, value)
    .then((data) => {
      dispatch(getRequiredDocumentsApplication(data, callback));
      return data;
    });
}
  
export function updateApplicationStatusForAdmin_(value, callback) {
  //   const { page, limit } = value || {};
  let url = `${APP_PATH}${APPLICATION_STATUS}?applicationId=${value.id}&status=${value.status}`;
  return (dispatch) => getData(url, value)
    .then((data) => {
      dispatch(updateApplicationStatusForAdmin(data, callback));
      return data;
    });
}
export function removeApplication_(value, callback) {
  let url = `${APP_PATH}${DELETE_APPLICATION}`;
  if (value !== undefined) {
    url += `?applicationId=${value}`;
  } 
  return (dispatch) => getData(url, value)
    .then((data) => {
      dispatch(removeApplication(data, callback));
      return data;
  });
}

export function markNotificationAsRead(value) {
    let url = `${APP_PATH}${ADMIN_MARK_READ_NOTIFICATION}`;
    if (value !== undefined) {
        url += `?notificationId=${value}`;
    }

    return (dispatch) => {
        // Make the API call but do not handle the response
        getData(url, value, false)
            .catch((error) => {
                console.error('Error while marking notification as read:', error);
            });
    };
}


export function roleAssignUser_(value, callback) {
  //   const { page, limit } = value || {};
  let url = `${APP_PATH}${ASSIGN_ROLE_LIST}`;
  return (dispatch) => getData(url, value)
    .then((data) => {
      dispatch(roleAssignUser(data, callback));
      return data;
  });
}


export function UpdateAssignUser_(value, callback) {
  //   const { page, limit } = value || {};
  let url = `${APP_PATH}${UPDATED_APLLICATION_ROLE}?applicationId=${value.applicationId}&adminId=${value.adminId}`;
  return (dispatch) => getData(url, value)
    .then((data) => {
      dispatch(UpdateAssignUser(data, callback));
      return data;
  });
}

//Service function to get admin notifications
export function getAdminNotifications(value, callback) {
    const {page = 0, size = 10, sort = 'timestamp'} = value || {};
    const url = `${APP_PATH}${GET_ADMIN_NOTIFICATIONS}?page=${page}&size=${size}&sort=${sort}`;

    return (dispatch) => {
        return getData(url, value) // Add `return` here to ensure the Promise is passed through
            .then((data) => {
                if (callback) callback(data, null);
                return data; // Pass the data back to the caller
            })
            .catch((error) => {
                console.error('Error fetching admin notifications:', error);
                if (callback) callback(null, error);
                throw error; // Rethrow the error so the caller can catch it
            });
    };
}
