import axios from 'axios';
//imported
import store from '../store';
import { authLogout } from '../store/actions/auth';

const access_token = localStorage.getItem('access_token');
axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;
axios.defaults.headers.common['Authorization'] = `${access_token}`;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = true;
// Create instance
const axiosInstance = axios.create();

// Axios wrapper to handle error
// const axiosWrapper = apiCall => apiCall.then(res => res.data).catch(err => Promise.reject(err));

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 403) {
         store.dispatch(authLogout());
    }
    return Promise.reject(error);
  },
);
export default axiosInstance;
