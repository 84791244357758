import React, {useState, useEffect} from "react";
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
//imported
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';

import { css } from "@emotion/react";
import FadeLoader from "react-spinners/FadeLoader";

const Layout = (props) => {   
    const override = css`
    display: block;
    margin: 0 auto;
    `;
    let [color, setColor] = useState("#02549E");

    //sidebar
    const [isOpen, setIsOpen] = useState(false)
    const sidebarHideShow = () => {
        setIsOpen(!isOpen)
    };


    useEffect(() => {
        // sidebarHideShow()
    }, [isOpen])

    return(
            <div className={`container-scroller ${isOpen ? 'sidebar-icon-only' : ''}`}>
                <Helmet titleTemplate={`%s | ${process.env.REACT_APP_APPNAME}`} /> 
                <Header sidebarHideShow={sidebarHideShow}/> 
                <div className="container-fluid page-body-wrapper">
                    <Sidebar isOpen={isOpen}/> 
                    <div className="main-panel">
                        <div className="content-wrapper">
                            {React.cloneElement(props.children)}
                            {props.loader==true &&
                                <div className="main-loader">
                                    <FadeLoader color={color} loading={props.loader} css={override} size={1000} />
                                </div>
                            }
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>

    )
}
const mapStateToPros = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
        loader: state.persistStore.loader,
    }
};

export default connect(
  mapStateToPros,
)(Layout);




