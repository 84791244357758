import React, { useState } from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import {  Redirect } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import LoadingButton from 'components/shared/LoadingButton';
import { LOGIN } from 'helpers/viewContent';
import {MAIN_LOGO} from 'utils/constant'
import {login} from 'store/services/authService'
//imported
import * as Path from 'routes/paths';
import * as actions from 'store/actions/index.js';
const renderField = ({
    input,
    placeholder,
    type,
    autoComplete,
    meta: { touched, error, warning }
}) => (
    <>
        <input {...input} type={type} placeholder={placeholder} autoComplete={autoComplete} className={`form-control ${touched && error ? `is-invalid` : ''}`}/>
        
        {touched &&
            (error && <span className="invalid-feedback">{error}</span>)
        }
    </>
)

const Login = (props) => {
    const [sending, setSending] = useState(false);
    const [success, setSuccess] = useState(false);
    const { handleSubmit, submitting} = props;

    const [values, setValues] = useState({
        email:"",
        password:"",
    });

    const handleSubmit_ = async (values) => {
        try{
            let obj = {
                ...values,
                deviceToken: "",
                deviceType: 0
            }
            let msz = "Login Successfully";
            obj = JSON.stringify(obj)
            setSending(true);
            props.dispatch(actions.persist_store({ loader:true }));
            await props.dispatch(login(obj, msz)).then((res) => {
                setSending(false);
                props.dispatch(actions.persist_store({  loader:false }));
                setSuccess(true);
            });
        }catch(err) {
            console.log(err);
            props.dispatch(actions.persist_store({ loader:false }));
            setSending(false);
        }
    }

    return(
        <>
        {success==true &&
            <Redirect to={Path.dashboard} />
        }
        <Helmet title={LOGIN.CURRENT_MODULE} />
        <div className="container-fluid page-body-wrapper full-page-wrapper">
            <div className="content-wrapper d-flex align-items-center auth">
                <div className="row flex-grow">
                    <div className="col-lg-4 mx-auto">
                        <div className="auth-form-light text-left p-5">
                            <div className="brand-logo">
                            <img src={MAIN_LOGO} className="loginLogo"/>
                            </div>
                            <h4>Hello! let's get started</h4>
                            <h6 className="font-weight-light">Sign in to continue.</h6>
                            <form className="pt-3" onSubmit={handleSubmit(handleSubmit_)} autoComplete="off">
                                <div className="form-group">
                                    <Field 
                                        name="email" 
                                        component={renderField}
                                        placeholder="E-Mail"
                                        type="email" 
                                        autoComplete="off"
                                    />
                                </div>
                                <div className="form-group">
                                    <Field 
                                        name="password" 
                                        component={renderField}
                                        placeholder="Password"
                                        type="password" 
                                        autoComplete="new-password"
                                    />
                                </div>
                                <div className="mt-3">
                                    <LoadingButton
                                        type="submit"
                                        className="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn"
                                        loading={sending}
                                        disabled={submitting}
                                    >
                                        {!sending ? 'SIGN IN' : 'Please wait...'}
                                    </LoadingButton>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>

    )
}

const validate = (values) => {
    const errors = {}
    //email
    if (!values.email) {
      errors.email = 'Email Required'
    }
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address'
    }
    //password
    if (!values.password) {
      errors.password = 'Password Required'
    }
    else if (values.password.length < 6) {
      errors.password = 'Must be 6 characters or greater'
    }
    return errors
}

const mapStateToProps = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

const LoginForm = reduxForm({
	form: 'login',
    validate
})(Login);

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);